<template>
  <div class="auther-content">
    <Header />
    <div class="auther-container" v-if="!isDel">
      <div class="head">
        <div class="center">
          <div class="info">
            <div class="left">
              <div class="top">
                <img
                  v-if="autherObj.headPhoto"
                  :src="$global.imgUrl + autherObj.headPhoto"
                  alt=""
                  class="headImg"
                />
                <span v-else class="img">{{
                  autherObj.realname | imgFormatting
                }}</span>
                <div class="message">
                  <div class="boxs">
                    <span class="name">{{ autherObj.realname }}</span>
                    <p
                      class="jiaoshou"
                      style="
                        display: none;
                        width: 200px;
                        overflow: hidden;
                        height: 39px;
                        line-height: 39px;
                      "
                    >
                      <i v-for="v in autherObj.titles" :key="v.id"
                        >{{ v.levelTwo }}
                      </i>
                    </p>
                    <Button
                      class="btn"
                      v-throttleClick
                      v-if="autherObj.reviewStatus !== 2"
                      @click="authenticationFn(autherObj.reviewStatus)"
                      >认证该学者</Button
                    >
                    <ul class="icon" v-if="autherObj.reviewStatus === 2">
                      <li v-for="(icon, i) in autherObj.honours" :key="i">
                        <img
                          v-lazy="$global.imgUrl1 + icon.honourLogo"
                          :title="icon.honourName"
                          alt=""
                        />
                      </li>
                    </ul>
                    <Button
                      type="primary"
                      shape="circle"
                      v-throttleClick
                      v-if="autherObj.reviewStatus === 2 && !isOnfocus"
                      @click="focusScholar(autherObj.userId)"
                      >关注</Button
                    >
                    <Button
                      class="see"
                      type="primary"
                      shape="circle"
                      v-if="autherObj.reviewStatus === 2 && isOnfocus"
                      v-throttleClick
                      @click="focusScholar(autherObj.userId, true)"
                      >已关注</Button
                    >
                    <Button
                      type="primary"
                      shape="circle"
                      v-if="autherObj.reviewStatus === 2"
                      @click="
                        toMessages(
                          autherObj.userId,
                          autherObj.reviewStatus,
                          autherObj.realname,
                          autherObj.headPhoto
                        )
                      "
                      >私信</Button
                    >
                    <Button
                      class="seeBtn"
                      type="primary"
                      shape="circle"
                      ghost
                      v-if="autherObj.reviewStatus === 2"
                      @click="flag1 = true"
                      >查看百家号</Button
                    >
                    <div class="share">
                      <img
                        style=""
                        :src="require('@/assets/imgs/share.png')"
                        alt=""
                      />
                      <ul class="share_box">
                        <div class="sanjiao"></div>
                        <li @click.stop="weChat" class="weChat">
                          <img src="@/assets/img/details/weChat.png" alt="" />
                          <div>微信</div>
                          <div
                            :id="`WXqrcode${$route.query.id}`"
                            :ref="`WXqrcode${$route.query.id}`"
                            class="ma"
                          ></div>
                        </li>
                        <li @click.stop="weibo">
                          <img
                            src="@/assets/img/details/microblog.png"
                            alt=""
                          />
                          <div>新浪微博</div>
                        </li>
                        <li @click.stop="qq" class="qq">
                          <img src="@/assets/img/details/QQ.png" alt="" />
                          <div>QQ</div>
                          <div
                            :id="`QQqrcode${$route.query.id}`"
                            :ref="`QQqrcode${$route.query.id}`"
                            class="ma"
                          ></div>
                        </li>
                        <li @click.stop="space">
                          <img src="@/assets/img/details/qqSpace.png" alt="" />
                          <div>QQ空间</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="bot">
                    <span class="unit"
                      ><span
                        style="margin-right: 10px"
                        v-if="autherObj.orgName"
                        >{{ autherObj.orgName }}</span
                      >
                      <span style="margin: 0 10px">-</span>
                      <span v-if="autherObj.job"> {{ autherObj.job }}</span>
                    </span>
                    <span class="seeNum" v-if="autherObj.views"
                      >{{ autherObj.views }}人看过</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="right">
              <img :src="$global.imgUrl + autherObj.suidQrCode" alt="" />
              <span class="font">SUID：{{ autherObj.suid }}</span>
            </div>
            <div class="qr" @click="handleCard">
              <img :src="require('@/assets/imgs/qr.png')" alt="" />
            </div>
          </div>
          <div class="bottom" style="color: #fff">
            <span>
              <span>学历/学位：</span>
              <span>{{ autherObj.education }}</span>
              <span v-show="autherObj.inStudy == 1">（在读）</span>
            </span>
            <span style="margin: 0 10px;">|</span>
            <span>
              <span>职称：</span>
              <span>
                <span v-for="(v, i) in autherObj.titles" :key="v.id">
                  <span>{{ v.levelTwo }}（{{ v.levelOneName }}）</span>
                  <span v-show="i !== autherObj.titles.length - 1">、</span>
                </span>
              </span>
            </span>
            <span style="margin: 0 10px;">|</span>
            <span>
              <span>学科领域：</span>
              <span v-for="(v, i) in autherObj.subjects" :key="v.id">
                <span>{{ v.levelTwoName }}</span>
                <span v-show="i !== autherObj.subjects.length - 1">、</span>
              </span>
            </span>
          </div>
        </div>
      </div>
      <div class="main">
        <div class="content">
          <ul class="nav">
            <li>
              <router-link
                :to="{ path: '/autherplain', query: { id: $route.query.id } }"
                >简介</router-link
              >
            </li>
            <li v-if="statusList[1] && statusList[1].status == 1">
              <router-link
                :to="{
                  path: '/autherachievements',
                  query: { id: $route.query.id },
                }"
                >成果</router-link
              >
            </li>
            <!-- <li v-if="statusList[2] && statusList[2].status == 1">
              <router-link
                :to="{ path: '/autherblog', query: { id: $route.query.id } }"
                >资讯</router-link
              >
            </li> -->
            <li v-if="statusList[3] && statusList[3].status == 1">
              <router-link
                :to="{ path: '/autherdynamic', query: { id: $route.query.id } }"
                >动态</router-link
              >
            </li>
            <li v-if="statusList[4] && statusList[4].status == 1">
              <router-link
                :to="{
                  path: '/autheranalysis',
                  query: { id: $route.query.id },
                }"
                >统计</router-link
              >
            </li>
          </ul>
          <router-view></router-view>
        </div>
        <div class="side">
          <div class="mechanism top">
            <div class="title">现任职机构</div>
            <div class="info">
              <span class="school" @click="goRecommend(autherObj.orgId)">{{
                autherObj.orgName
              }}</span>
              <img
                v-if="autherObj.orgLogo"
                :src="$global.imgUrl + autherObj.orgLogo"
                alt=""
              />
              <img v-else :src="require('@/assets/imgs/default.png')" alt="" />
            </div>
          </div>
          <div
            class="picture top"
            v-if="
              statusList[0] &&
              statusList[0].childList &&
              statusList[0].childList[6].status == 1
            "
          >
            <div class="title">学者图集</div>
            <router-link
              v-if="imgObj"
              :to="{
                path: '/autherpicture',
                query: {
                  id: $route.query.id,
                },
              }"
              target="_blank"
            >
              <img v-if="imgObj" :src="$global.imgUrl + imgObj" alt="" />
            </router-link>
            <img v-else :src="require('@/assets/qietu/atlas.png')" alt="" />
          </div>
          <div class="cooperation top">
            <h2 class="title">合作学者</h2>
            <ul class="box" v-if="sideList.length > 0">
              <li v-for="(v, i) in sideList" :key="i">
                <img
                  v-if="v.headPhoto"
                  :src="$global.imgUrl + v.headPhoto"
                  alt=""
                />
                <img
                  class="headImg"
                  v-else
                  :src="require('@/assets/imgs/head_bg.png')"
                />
                <div class="info">
                  <div class="name" @click="goAuther(v)">{{ v.value }}</div>
                  <div class="school">{{ v.unit }}</div>
                </div>
              </li>
              <div class="all" @click="showAll = true">
                查看全部<Icon type="ios-arrow-round-forward" class="icon" />
              </div>
            </ul>
            <img
              v-else
              class="noImg"
              :src="require('@/assets/qietu/noStudent.png')"
              alt=""
            />
          </div>
        </div>
      </div>
      <AllAuther v-if="showAll" @hideFlag="hideFlag"></AllAuther>
      <Modal v-model="flag2" title="认证提示" :footer-hide="true">
        <p>该学者正在被其他用户认证，暂时无法申请，请稍后再试</p>
      </Modal>
      <Modal v-model="flag1" title="即将离开百家在线" ok-text="继续访问">
        <p>您即将进入中文知识网，点击“继续访问”可查看具体内容。</p>
        <p>
          中文知识网是国家知识服务平台融合出版分平台，是中文集团旗下子公司运营的知识服务平台。中文知识网拥有百家在线、百科在线、同行评议等子平台。请放心跳转。
        </p>
        <div slot="footer">
          <Button @click="flag1 = false">取消</Button>
          <Button type="primary" @click="ok">继续访问</Button>
        </div>
      </Modal>
      <div @click="showCard = false" class="card-container" v-if="showCard">
        <div @click.stop="showCard = true" class="card">
          <div class="left">
            <div class="info">
              <img
                class="imgInfo"
                v-if="autherObj.headPhoto"
                :src="$global.imgUrl + autherObj.headPhoto"
                alt=""
              />
              <span class="imgInfo" v-else>{{
                autherObj.realname | imgFormatting
              }}</span>
              <p>
                <span class="name">{{ autherObj.realname }}</span>
              </p>
              <p class="unit">{{ autherObj.orgName }}</p>
            </div>
            <hr />
            <img
              class="erweima"
              :src="$global.imgUrl + autherObj.suidQrCode"
              alt=""
            />
          </div>
          <div class="right">
            <a
              @click="
                download(
                  `${$global.url1}/zw-user/scholar/nf/downBusiness?userId=${$route.query.id}`
                )
              "
              class="downcard"
            >
              <span>下载</span>
              <span>名片</span>
            </a>
          </div>
        </div>
      </div>
      <certificationModal :modalShow="flag3" @closeModal="flag3 = false" />
    </div>
    <div class="isDel" v-else>
      <img :src="require('@/assets/qietu/isDel.png')" alt="" />
      <p>学者已下架</p>
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  focusScholarApi,
  unfocusScholarApi,
  isAttention,
} from "../../apis/user";
import AllAuther from "./components/AllAuther.vue";
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import certificationModal from "./components/certificationModal.vue";
import QRCode from "qrcodejs2";
export default {
  components: {
    AllAuther,
    certificationModal,
    Header,
    Footer,
  },
  filters: {
    imgFormatting(query) {
      if (query === undefined || query === "") return "";
      return query.slice(query.length - 2, query.length);
    },
  },
  data() {
    return {
      isDel: false,
      sideList: [],
      showAll: false,
      autherObj: {},
      flag1: false,
      flag2: false,
      flag3: false,
      showCard: false,
      screenWidth: document.body.clientWidth,
      imgObj: {},
      isOnfocus: false,
      WXurl: "",
      QQurl: "",
      statusList: [],
    };
  },
  created() {
    this.getPrivacyList({
      userId: this.$route.query.id,
    });
    this.qq();
    this.weChat();
    this.getList({
      id: this.$route.query.id,
    });
    this.spaceScholarInfo({
      userId: this.$route.query.id,
    });
    this.spaceScholarGallery({
      pageNo: 1,
      pageSize: 10,
      userId: this.$route.query.id,
    });
    if (this.hasLogin) {
      this.isAttentionScholar({ scholarId: this.$route.query.id });
    }
  },
  watch: {
    /* 监听*/
    screenWidth(val) {
      this.screenWidth = val;
      if (this.screenWidth >= 540) {
        this.showCard = false;
      }
    },
  },
  computed: {
    ...mapState(["hasLogin"]),
  },
  mounted() {
    this.$bus.$on("mainDocument", this.handleDoucment);
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        this.screenWidth = window.screenWidth;
      })();
    };
  },
  methods: {
    isAndroid_ios() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //安卓
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isAndroid) {
        return true;
      } else if (isIOS) {
        return false;
      }
    },
    // 开放APP方法,下载名片至相册
    download(Url) {
      if (this.isAndroid_ios()) {
        window.android.UnderBusinessCard(Url);
      } else {
        window.webkit.messageHandlers.UnderBusinessCard.postMessage(Url);
      }
    },
    async getPrivacyList(data) {
      const resp = await this.$apis.autherServe.getPrivacyList(data);
      if (resp.data) {
        this.statusList = resp.data;
      }
    },
    toMessages(id, type, name, foto) {
      if (this.hasLogin) {
        this.$router.push({
          name: "letterUser",
          params: { id, name, foto, type },
        });
      } else {
        this.$store.commit("showAlert", true);
      }
    },
    goAuther(v) {
      let url = this.$router.resolve({
        path: "/autherdetail",
        query: { id: v.authorId },
      });
      window.open(url.href, "_blank");
    },
    async isAttentionScholar(data) {
      const resp = await isAttention(data);
      if (resp.resultDesc !== "token过期!") {
        this.isOnfocus = resp.content.isAttention;
      }
    },
    // 学者关注按钮
    async focusScholar(id, status = false) {
      if (this.hasLogin) {
        const resp = status
          ? await unfocusScholarApi({ scholarId: id })
          : await focusScholarApi({ scholarId: id });
        if (resp.messageType === "Success") {
          this.isOnfocus = !status;
          this.$Message.success(status ? "取消关注成功" : "关注成功");
        } else {
          this.$Message.error(resp.resultDesc);
        }
      } else {
        this.$store.commit("showAlert", true);
      }
    },
    goRecommend(id) {
      window.open(`${this.$global.testUrl}recommend?id=${id}&type=2`, "_blank");
    },
    ok() {
      window.open(
        `${this.$global.testUrl}bjzxdetail?title=百家在线&id=${this.$route.query.id}`,
        "_blank"
      );
    },
    handleCard() {
      this.showCard = true;
    },
    async spaceScholarGallery(data) {
      const resp = await this.$apis.autherServe.spaceScholarGallery(data);
      this.imgObj = resp.content.galleryCover;
    },
    async spaceScholarInfo(data) {
      const resp = await this.$apis.autherServe.spaceScholarInfo(data);
      if (resp.resultCode === "200") {
        this.autherObj = resp.data;
        document.title = this.autherObj.realname + "-百家在线";
      } else if (resp.resultCode === "1008") {
        this.isDel = true;
      }
    },
    async getList(data) {
      const resp = await this.$apis.autherServe.getList(data);
      this.sideList = resp.data.list;
    },
    authenticationFn(status) {
      if (this.hasLogin) {
        if (status === 4) {
          this.flag2 = true;
        } else {
          this.flag3 = true;
        }
      } else {
        this.$store.commit("showAlert", true);
      }
    },
    hideFlag(flag) {
      this.showAll = flag;
    },
    handleDoucment(e) {
      if (e.target.className === "card-container") {
        this.showCard = false;
      }
      if (e.target.className === "all") return;
      if (e.target.className === "all-auther-container") {
        this.showAll = false;
      }
    },
    weChat() {
      if (this.WXurl !== "") return;
      this.WXurl =
        this.$global.url3 + `/autherachievements?id=${this.$route.query.id}`;
      // 使用$nextTick确保数据渲染
      this.$nextTick(() => {
        this.WXcrateQrcode();
      });
    },
    WXcrateQrcode() {
      this.qr = new QRCode("WXqrcode" + this.$route.query.id, {
        width: 150,
        height: 150, // 高度
        text: this.WXurl, // 二维码内容
      });
    },
    QQcrateQrcode() {
      this.qr = new QRCode("QQqrcode" + this.$route.query.id, {
        width: 150,
        height: 150, // 高度
        text: this.QQurl, // 二维码内容
      });
    },
    qq() {
      if (this.QQurl !== "") return;
      this.QQurl =
        this.$global.url3 + `/autherachievements?id=${this.$route.query.id}`;
      // 使用$nextTick确保数据渲染
      this.$nextTick(() => {
        this.QQcrateQrcode();
      });
    },
    weibo() {
      let url = encodeURIComponent(window.location.href);
      let srcUrl = "";
      this.autherObj.headPhoto
        ? (srcUrl = `pic=${this.$global.imgUrl + this.autherObj.headPhoto}`)
        : (srcUrl = "");
      window.open(
        `https://service.weibo.com/share/share.php?${srcUrl}url=${url}&&title=${this.autherObj.headPhoto}`
      );
    },
    space() {
      let url = encodeURIComponent(window.location.href);
      let srcUrl = "";
      this.autherObj.headPhoto
        ? (srcUrl = `pics=${this.$global.imgUrl + this.autherObj.headPhoto}`)
        : (srcUrl = "");
      window.open(
        `https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?${srcUrl}url=${url}&&title=${this.autherObj.headPhoto}`
      );
    },
  },
};
</script>

<style lang="scss">
@media (max-width: 540px) {
  .auther-container {
    width: 100% !important;
    .head {
      min-width: 100% !important;
      width: 100% !important;
      height: 200px !important;
      margin: 0 auto !important;
      background: url("~@/assets/qietu/bgx.png") no-repeat !important;
      background-size: 100% 200px !important;
      .center {
        width: 100% !important;
        .info {
          width: 100% !important;
          padding: 40px 0 15px !important;
          padding-left: 10px !important;
          display: flex;
          .left {
            width: 100%;
            .top {
              display: flex;
              .headImg {
                width: 65px !important;
                height: 65px !important;
                border-radius: 50%;
                margin-right: 10px !important;
              }
              .img {
                // width: 65px !important;
                height: 65px !important;
                background: #1876f0;
                border-radius: 50px;
                margin-right: 10px !important;
                text-align: center;
                line-height: 65px !important;
                font-size: 25px !important;
              }
              .message {
                width: 100% !important;
                div {
                  display: flex;
                  align-items: center;
                  margin-bottom: 10px;
                  img {
                    cursor: pointer;
                  }
                  .unit {
                    font-size: 16px;
                  }
                  .seeNum {
                    font-size: 16px;
                    color: #b3b3b3;
                  }
                }
                .icon {
                  width: auto !important;
                  max-width: 190px !important;
                  overflow: hidden !important;
                  // display: inline-block !important;
                  position: absolute;
                  top: 70px;
                  left: 82px;
                  li {
                    width: 17px;
                    height: 17px;
                    margin: 0 3px;
                    img {
                      width: 100%;
                      height: 100%;
                    }
                  }
                }
                .ivu-btn {
                  margin: 0 10px;
                }
                .name {
                  font-size: 18px !important;
                }
                span {
                  white-space: nowrap;
                  margin-right: 29px;
                }
                .btn {
                  display: inline-block;
                  width: 137px;
                  height: 38px;
                  text-align: center;
                  line-height: 38px;
                  background: #00a4ff;
                  border-radius: 19px;
                  cursor: pointer;
                  border: none;
                  color: #fff;
                }
                .seeBtn:hover {
                  color: #fff;
                  background-color: #2d8cf0;
                  border-color: #2d8cf0;
                }
              }
            }
            .bottom {
              margin-top: 10px;
              font-size: 16px;
            }
          }
          .top {
            p:first-child {
              flex-wrap: wrap;
            }
            button,
            .share,
            .btn {
              display: none !important;
            }
            .bot {
              display: none !important;
            }
            .btn {
              padding: 0 10px !important;
              width: auto !important;
            }
            .name {
              width: 100px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              font-size: 25px !important;
              margin-right: 0 !important;
            }
            .jiaoshou {
              width: auto !important;
              display: inline-block !important;
              // white-space:pre !important;
              i {
                // width: 70px !important;
                height: 18px;
                margin-top: 5px;
                font-style: normal;
                background: #00a4ff;
                border-radius: 6px;
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: center;
                line-height: 18px;
                padding: 0 10px;
                font-size: 12px !important;
                margin-right: 5px !important;
              }
            }
            .boxs {
              // width: 200px !important;
              display: flex !important;

              .name {
                width: auto !important;
                // max-width: 75px !important;
                font-size: 13px !important;
                margin-right: 10px !important;
                overflow: visible !important;
              }
            }
          }
          .bottom {
            width: 300px !important;
          }
          .right {
            display: none !important;
          }
          .qr {
            width: 45px;
            height: 45px;
            display: block !important;
            margin-right: 25px !important;
            img {
              width: 45px;
              height: 45px;
            }
          }
        }
        .bottom {
          margin-left: 10px !important;
          font-size: 12px;
        }
      }
    }
    .main {
      width: 100% !important;
      .side {
        display: none !important;
      }
      .content {
        width: 100% !important;
        background-color: #f9f9f9 !important;
        .nav {
          li {
            margin-right: auto !important;
          }
          a {
            font-size: small !important;
            &::after {
              width: auto !important;
            }
          }
        }
        .auther-plain-container,
        .achievements-container,
        .auther-blog-container,
        .auther-dynamic-container,
        .auther-analysis-container,
        .auther-dynamic-containers {
          width: 100% !important;
          min-height: 100vh !important;
          border: none !important;
        }
        .auther-dynamic-containers {
          .container {
            .update-container {
              width: 95% !important;
              padding: 15px !important;
              margin: 0 auto !important;
              margin-bottom: 20px !important;
              .headr {
                width: auto !important;
                .left {
                  img {
                    width: 40px !important;
                    height: 40px !important;
                  }
                  .info {
                    .name,
                    .update {
                      font-size: 12px !important;
                      display: inherit;
                      width: auto !important;
                    }
                  }
                }
                .right {
                  font-size: 12px !important;
                  // position: relative;
                  span {
                    font-size: 12px !important;
                    width: auto !important;
                  }
                }
              }
              .title {
                font-size: 18px !important;
              }
              .contents {
                font-size: 12px !important;
              }
            }
          }
        }
        .achievements-side {
          display: none !important;
        }
        .content-container {
          margin-left: 0 !important;
          border: none !important;
          .resultNum {
            font-size: 12px !important;
          }
          .achievement-container {
            .item {
              .title {
                font-size: 15px !important;
              }
              .info {
                font-size: 12px !important;
                .periodical {
                  max-width: 200px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  margin-right: 10px;
                }
              }
              // .icon-container{
              //   li{
              //     width: auto !important;
              //     height: 30px !important;
              //     img{
              //       width: 100%;
              //       height: 100%;
              //     }
              //     button{
              //       span{
              //         img{
              //           width: 100% !important;
              //           height: 100% !important;
              //         }
              //       }
              //     }
              //   }
              //   // .read .imgBtn{
              //   //   // width: 60px !important;
              //   //   height: 30px !important;
              //   // }
              // }
            }
            .ivu-page {
              font-size: 12px !important;
              .ivu-page-prev,
              .ivu-page-item,
              .ivu-page-next {
                min-width: 25px !important;
              }
            }
          }
        }
        .auther-plain-container {
          .top {
            display: none !important;
          }
          .box {
            border: none !important;
            font-size: 12px !important;
            .item {
              .title {
                font-size: 18px;
              }
              .btns {
                button {
                  height: 40px !important;
                  line-height: 40px !important;
                  font-size: 13px !important;
                  margin: 0 20px 10px 0 !important;
                }
              }
            }
            .content.btns {
              span {
                max-width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
        }
        .auther-dynamic-container {
          .update-container {
            width: 100% !important;
            border: none !important;
            .right {
              span {
                width: 100px !important;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }
          .container {
            width: auto !important;
            margin-right: 0 !important;
          }
        }
        .auther-blog-container {
          .item {
            .title {
              white-space: nowrap;
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        .follow-item-container {
          .main {
            width: 400px !important;
          }
        }
        .auther-analysis-container {
          .tree,
          .source,
          .auther,
          .keywords,
          .unit {
            width: 100% !important;
            background-color: #fff !important;
          }
        }
      }
    }
    .card-container {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.3);
      .card {
        width: 300px;
        height: 400px;
        background: #ffffff;
        border-radius: 6px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 0 27px 0 41px;
        display: flex;
        .left {
          margin-right: 60px;
          .info {
            width: 110px;
            margin-top: 38px;
            display: flex;
            flex-direction: column;
            .unit {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            .imgInfo {
              display: inline-block;
              width: 75px;
              height: 75px;
              border-radius: 50%;
              margin-bottom: 22px;
              background: #1876f0;
              color: #fff;
              text-align: center;
              font-size: 30px;
              line-height: 75px;
            }
            .name {
              font-size: 18px;
              color: #012654;
              margin-right: 8px;
              vertical-align: middle;
            }
            .professor {
              display: inline-block;
              width: 57px;
              height: 26px;
              background: #011b48;
              border-radius: 3px;
              font-size: 17px;
              font-weight: 400;
              color: #ffffff;
              text-align: center;
              line-height: 26px;
              vertical-align: middle;
            }
            .unit {
              font-size: 13px;
              color: #012654;
              margin-top: 13px;
            }
          }
          hr {
            margin-top: 37px;
          }
          .erweima {
            margin-top: 10px;
            width: 106px;
            height: 107px;
          }
        }
        .right {
          width: 60px;
          height: 100%;
          position: relative;
          background-image: url("~@/assets/imgs/bg1.png");
          .downcard {
            position: absolute;
            bottom: 46px;
            left: 0px;
            width: 60px;
            height: 58px;
            background: #00a4ff;
            border-radius: 4px;
            font-size: 17px;
            font-weight: 400;
            color: #ffffff;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-content: center;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.share:hover {
  .share_box {
    display: block;
  }
}
.share {
  position: relative;
  margin-bottom: 0 !important;
  &::after {
    content: "";
    display: block;
    width: 20px;
    height: 100px;
    background-color: #fff;
    position: absolute;
    right: -15px;
    top: -32px;
    opacity: 0;
  }

  img {
    width: 51px;
    height: 20px;
  }
  .share_box {
    cursor: pointer;
    display: none;
    right: -145px;
    position: absolute;
    width: 133px;
    height: 167px;
    background: #ffffff;
    box-shadow: 3px 1px 7px 0px rgba(8, 8, 64, 0.23);
    border-radius: 10px;
    li {
      div {
        color: #333;
      }
    }
    .weChat:hover {
      .ma {
        display: flex !important;
        justify-content: center;
      }
    }

    .weChat {
      position: relative;
      .ma {
        display: none !important;
        left: 123px !important;
        position: absolute !important;
        width: 200px !important;
        top: -10px !important;
        height: 167px !important;
        background: white !important;
        box-shadow: 3px 1px 7px 0px rgba(8, 8, 64, 0.23);
        border-radius: 10px;
      }
    }
    .qq:hover {
      .ma {
        display: flex !important;
        justify-content: center;
      }
    }
    .qq {
      position: relative;
      .ma {
        display: none !important;
        left: 123px !important;
        position: absolute !important;
        width: 200px !important;
        top: -90px !important;
        height: 167px !important;
        background: white !important;
        box-shadow: 3px 1px 7px 0px rgba(8, 8, 64, 0.23);
        border-radius: 10px;
      }
    }
    li {
      margin-top: 10px;
      margin-bottom: 0px;
      display: flex;
      flex-wrap: nowrap;
      //background: pink;
      justify-content: center;
      align-items: center;
      width: 133px;
      height: 30px;

      div {
        width: 60px;
        //background: yellow;
        margin-left: 10px;
      }
      img {
        width: 20px;
        height: 20px;
      }
    }
    .sanjiao {
      width: 0;
      height: 0;
      border-top: 3px solid transparent;
      border-right: 6px solid #fff;
      position: absolute;
      top: 50%;
      margin-top: -3px;
      left: -6px;
      border-bottom: 3px solid transparent;

      //background: pink;
    }
  }
}
.auther-container {
  width: 100%;
  background-color: #fff;
  .head {
    min-width: 1440px;
    height: 300px;
    background-image: url("~@/assets/qietu/bg.png");
    background-size: cover;
    margin: 0 auto;
    .center {
      width: 1440px;
      margin: 0 auto;
      .info {
        width: 1040px;
        padding: 88px 0 30px;
        display: flex;
        justify-content: space-between;
        color: #fff;
        .left {
          .top {
            display: flex;
            .headImg {
              width: 90px;
              height: 90px;
              border-radius: 50%;
              margin-right: 21px;
            }
            .img {
              width: 90px;
              height: 90px;
              background: #1876f0;
              border-radius: 50px;
              margin-right: 21px;
              text-align: center;
              line-height: 90px;
              font-size: 30px;
            }
            .message {
              div {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                img {
                  cursor: pointer;
                }
                .unit {
                  font-size: 16px;
                }
                .seeNum {
                  font-size: 16px;
                  color: #b3b3b3;
                }
              }
              .icon {
                width: 100%;
                height: 17px;
                margin: 15px 0;
                display: flex;
                li {
                  width: 17px;
                  height: 17px;
                  margin: 0 3px;
                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
              }
              .ivu-btn {
                margin: 0 10px;
              }
              .name {
                font-size: 40px;
              }
              span {
                white-space: nowrap;
                margin-right: 29px;
              }
              .btn {
                display: inline-block;
                width: 137px;
                height: 38px;
                text-align: center;
                line-height: 38px;
                background: #00a4ff;
                border-radius: 19px;
                cursor: pointer;
                border: none;
                color: #fff;
              }
              .seeBtn:hover {
                color: #fff;
                background-color: #2d8cf0;
                border-color: #2d8cf0;
              }
            }
          }
          .bottom {
            margin-top: 10px;
            font-size: 16px;
          }
        }
        .right {
          display: flex;
          flex-direction: column;
          img {
            width: 90px;
            height: 90px;
          }
          .font {
            font-size: 11px;
            color: #ffffff;
            display: inline-block;
            white-space: nowrap;
            letter-spacing: -1px;
          }
        }
        .qr {
          display: none;
        }
      }
    }
  }
  .main {
    width: 1440px;
    margin: 0 auto;
    display: flex;
    .content {
      width: 1040px;
    }
    .nav {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      margin-top: 40px;
      border-bottom: 1px solid #e6e6e6;
      li {
        width: 160px;
        margin-right: 40px;
        text-align: center;
        font-size: 18px;
        white-space: nowrap;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        .router-link-exact-active.router-link-active {
          color: #00a4ff;
          &::after {
            content: "";
            display: block;
            width: 160px;
            height: 8px;
            background-image: url("~@/assets/imgs/xz.png");
            background-size: 100% 100%;
          }
        }
        a {
          color: #333;
          display: inline-block;
          width: 100%;
          height: 100%;
        }
      }
    }
    .side {
      width: 360px;
      margin: 40px 0 40px 40px;
      .top {
        width: 360px;
        margin-bottom: 30px;
        background: #ffffff;
        border: 1px dotted #999999;
        border-radius: 6px;
        position: relative;
        padding: 30px;
        overflow: hidden;
        &::after {
          position: absolute;
          left: -1px;
          top: -1px;
          content: "";
          display: block;
          width: 100%;
          height: 4px;
          background-image: url("~@/assets/imgs/squre.png");
          background-size: 100% 100%;
        }
        &.mechanism {
          // height: 174px;
          .info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .school {
              font-size: 18px;
              color: #333333;
              cursor: pointer;
            }
            img {
              width: 70px;
              height: 70px;
            }
          }
          .title {
            cursor: pointer;
          }
        }
        &.picture {
          height: 314px;
          img {
            width: 300px;
            height: 200px;
          }
        }
        &.cooperation {
          // height: 587px;
          .box {
            li {
              display: flex;
              margin: 30px 0;
              align-items: center;
              img {
                width: 60px;
                height: 60px;
                margin-right: 15px;
                border-radius: 50%;
              }
              .info {
                .name {
                  font-size: 18px;
                  color: #00a4ff;
                  cursor: pointer;
                }
                .school {
                  width: 223px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  font-size: 14px;
                  color: #999999;
                  margin-top: 8px;
                }
              }
            }
          }
          .noImg {
            width: 300px;
            height: 200px;
          }
          .all {
            font-size: 18px;
            color: #00a4ff;
            cursor: pointer;
            margin-top: 29px;
            .icon {
              font-size: 18px;
              color: #00a4ff;
              font-weight: bold;
            }
          }
        }
        .title {
          font-size: 22px;
          font-weight: 400;
          color: #00a4ff;
          margin-bottom: 25px;
        }
      }
    }
  }
}
.isDel {
  margin: 100px 0;
  width: 100%;
  height: 100%;
  text-align: center;
  img {
    width: 463px;
    height: 404px;
  }
  p {
    font-size: 16px;
    font-family: Source Han Sans SC;
    color: #999999;
  }
}
</style>
