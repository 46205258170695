<template>
  <div class="all-auther-container">
    <div class="cooperation">
      <div class="head">
        <h2 class="title">合作学者</h2>
        <span @click="hideFlag">X</span>
      </div>
      <ul class="box">
        <li v-for="(v, i) in sideList" :key="i">
          <img v-if="v.headPhoto" :src="$global.imgUrl + v.headPhoto" alt="" />
          <img
            class="headImg"
            v-else
            :src="require('@/assets/imgs/head_bg.png')"
          />
          <div class="info">
            <div class="name">{{ v.value }}</div>
            <div class="school">{{ v.unit }}</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sideList: [],
    };
  },
  created() {
    this.getList({
      id: this.$route.query.id,
      pageSize: 999999,
    });
  },
  methods: {
    async getList(data) {
      const resp = await this.$apis.autherServe.getList(data);
      this.sideList = resp.data.list;
    },
    hideFlag() {
      this.$emit("hideFlag", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.all-auther-container {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
}
.cooperation {
  width: 360px;
  background: #ffffff;
  border: 1px dotted #999999;
  border-radius: 6px;
  position: relative;
  padding: 30px;
  height: 587px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .box {
    margin-top: 25px;
    overflow: auto;
    height: 450px;
    li {
      display: flex;
      margin-bottom: 30px;
      align-items: center;
      img {
        width: 60px;
        height: 60px;
        margin-right: 15px;
        border-radius: 50%;
      }
      .info {
        .name {
          font-size: 18px;
          color: #00a4ff;
        }
        .school {
          font-size: 14px;
          color: #999999;
          margin-top: 8px;
        }
      }
    }
  }
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      cursor: pointer;
    }
  }
  &::after {
    position: absolute;
    left: -1px;
    top: -1px;
    content: "";
    display: block;
    width: 100%;
    height: 4px;
    background-image: url("~@/assets/imgs/squre.png");
    background-size: 100% 100%;
  }
  .title {
    font-size: 22px;
    font-weight: 400;
    color: #00a4ff;
  }
}
</style>
