<template>
  <footer class="footer-container">
    <img v-lazy="require('@/assets/imgs/footer.png')" alt="" />
    <div class="footer">
      <p>
        © 2021 百家在线数据集团有限公司 |
        <a
          href="https://beian.miit.gov.cn/"
          target="_blank"
          style="color: #fff"
        >
          京ICP备19039670号-2
        </a>
      </p>
    </div>
  </footer>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 540px) {
  .footer-container {
    display: none !important;
  }
}
.footer-container {
  min-width: 1440px;
  margin: 0 auto;
  img {
    width: 100%;
    height: 51px;
    display: block;
  }
  .footer {
    font-size: 14px;
    height: 132px;
    background: #444444;
    padding-top: 40px;
    text-align: center;
    p {
      color: #ffffff;
    }
  }
}
</style>
