<template>
  <Modal v-model="show" width="710" :mask-closable="false">
        <div slot="header">
            <h4 class="modal-title">认证学者</h4>
            <p class="modal-subtitle">您正在进行&lt;学者姓名&gt;的认领操作，请填写相关证明材料</p>
        </div>
        <div class="content-box">
            <Form ref="certificationForm" :model="formData" :rules="formRules" label-position="top">
                <Form-item label="身份证号：" prop="idCard" v-if="!isScholars">
                    <Input v-model="formData.idCard" placeholder="身份证号"></Input>
                    <input type="text" style="width: 0; opacity: 0;border: none;">
                </Form-item>
                <Form-item label="身份证图片：" prop="idCardV" v-if="!isScholars">
                    <Upload
                        class="shenfenUpload"
                        accept=".png,.jpg,.jpeg"
                        :headers="{ token: token }"
                        :show-upload-list="false"
                        :format="['jpg', 'jpeg', 'png']"
                        :max-size="5120"
                        :action="`${$global.url1}/zw-public/web/common/upload`"
                        :on-exceeded-size="beyondSize"
                        :on-success="idCardFrontFrontSuccess"
                    >
                        <img
                            class="IDimg"
                            v-if="formData.idCardFront.length !== 0"
                            v-lazy="$global.imgUrl + formData.idCardFront"
                            alt
                            />
                        <img v-else class="IDimg" src="@/assets/img/userInfo/zid.png" alt />
                    </Upload>
                    <Upload
                        class="shenfenUpload"
                        style="margin-left: 30px"
                        accept=".png,.jpg,.jpeg"
                        :headers="{ token: token }"
                        :show-upload-list="false"
                        :format="['jpg', 'jpeg', 'png']"
                        :max-size="5120"
                        :action="`${$global.url1}/zw-public/web/common/upload`"
                        :on-exceeded-size="beyondSize"
                        :on-success="idCardBackBackSuccess"
                    >
                        <img
                            class="IDimg"
                            v-if="formData.idCardBack.length !== 0"
                            v-lazy="$global.imgUrl + formData.idCardBack"
                            alt
                        />
                        <img v-else class="IDimg" src="@/assets/img/userInfo/fid.png" alt />
                    </Upload>
                    <div class="tishi">建议分辨率不低于1920*1080，大小不超过5M。</div>
                </Form-item>
                <Form-item label="证明材料：" prop="proveV">
                    <div class="tishi1">图片（支持JPG、JPEG、PNG格式，图片不超过 10M）{{'('+formData.evidencePhotos.length+'/5)'}}</div>
                    <div class="demo-upload-list" v-for="(item, index) in formData.evidencePhotos" :key="index">
                        <img v-lazy="$global.imgUrl + item" alt="" />
                        <div class="demo-upload-list-cover">
                            <Icon type="ios-trash-outline" @click.native="removePhotos(index)" />
                        </div>
                        </div>
                        <Upload
                            class="photoUplode"
                            ref="upload"
                            type="drag"
                            accept=".png, .jpg, .jpeg"
                            :format="['jpg', 'jpeg', 'png']"
                            :max-size="10240"
                            :headers="{ token: token }"
                            :action="`${$global.url1}/zw-public/web/common/upload`"
                            :show-upload-list="false"
                            :on-exceeded-size="beyondSize"
                            :on-success="PhotoUplode"
                            :before-upload="beforeUplode"
                        >
                            <div class="photoUplodeBox">
                                <Icon type="ios-camera" size="20"></Icon>
                            </div>
                        </Upload>
                    <div class="tishi1">文件（支持PDF格式，文件不超过 30M）{{'('+formData.evidenceFiles.length+'/5)'}}</div>
                    <Upload
                        ref="files"
                        class="files"
                        accept=".pdf"
                        :format="['pdf']"
                        :headers="{ token: token }"
                        :max-size="30720"
                        :action="`${$global.url1}/zw-public/web/common/upload`"
                        :on-exceeded-size="beyondSize"
                        :on-success="uplodeFile"
                        :on-remove="removeFile"
                        :before-upload="beforeFilesUplode"
                    >
                        <Button class="filesBtn">选择文件</Button>
                    </Upload>
                </Form-item>
            </Form>
        </div>
        <div slot="footer">
            <Button v-throttleClick="2000" class="Btn" @click="submit">提交</Button>
        </div>
  </Modal>
</template>

<script>
import { mapState } from "vuex";
export default {
    props: {
        modalShow: {
            tyep: Boolean,
            default: false
        }
    },
    created() {
        this.isScholars = JSON.parse(sessionStorage.getItem("userInfo")) ? JSON.parse(sessionStorage.getItem("userInfo")).user.customerType == 2  : false
    },
    computed: {
        ...mapState(["token", "userInfo"]),
        show: {
            get() {
                return this.modalShow
            },
            set() {
                this.close()
            }
        },
    },
    data() {
        // 自定义身验证身份证号码
        const validateIdCard = (rule, value, callback) => {
            let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/; 
            if (value === '') {
                callback(new Error('身份证号不能为空'));
            }else if(!reg.test(value)){
                callback(new Error('请输入正确的身份证号'));
            } else {
                callback()
            }
        };
        // 自定义表单验证身份证图片
        const validateIdCardImg = (rule, value, callback) => {
            if (this.formData.idCardFront === '' || this.formData.idCardBack === '') {
                return callback(new Error('请上传身份证'));
            } else {
                callback()
            }
        };
        // 证明材料是否上传
        const validateprove = (rule, value, callback) => {
            if (this.formData.evidencePhotos.length === 0 && this.formData.evidenceFiles.length === 0) {
                return callback(new Error('请上传证明材料'));
            } else {
                callback()
            }
        };
        return {
            isScholars: false,
            idCardV: null, // 身份证图片表单验证
            proveV: null, // 证明材料表单验证
            formData: {
                claimedUserId: this.$route.query.id,
                idCardFront: '',
                idCard: '',
                idCardBack: '',
                evidencePhotos: [],
                evidenceFiles: [],
            },
            formRules: {
                idCard: [{ required: true, validator: validateIdCard, trigger: 'blur' }],
                idCardV: [{ required: true, validator: validateIdCardImg, trigger: 'change' }],
                proveV: [{ required: true, validator: validateprove, trigger: 'change' }],
            },
        }
    },
    methods: {
         // 超出文件上传大小
        beyondSize(file) {
            this.$Message.warning({
                content: file.name + " 文件大小超出限制,请重新上传",
                duration: 2,
            });
        },
        //身份证上传图片
        idCardFrontFrontSuccess(res) { // 正面
            this.formData.idCardFront = res.content.url;
        },
        idCardBackBackSuccess(res) { // 反面
            this.formData.idCardBack = res.content.url;
        },
        // 图片上传
        beforeUplode() { // 上传之前
            if (this.formData.evidencePhotos.length >= 5) {
                this.$Notice.warning({
                    title: "上传图片已达上限",
                    duration: 1.5
                });
                return false
            }
        },
        PhotoUplode(res) { // 上传成功
            this.formData.evidencePhotos.push(res.content.url)
        },
        removePhotos(index) { // 删除图片
            this.formData.evidencePhotos.splice(index, 1);
        },
        // 文件上传
        beforeFilesUplode() {
            if (this.formData.evidenceFiles.length >= 5) {
                this.$Notice.warning({
                    title: "上传文件已达上限",
                    duration: 1.5
                });
                return false
            }
        },
        uplodeFile(res,file) { // 上传成功钩子
            let obj = {}
            obj.fileName = file.name
            obj.fileUri = file.response.content.url
            this.formData.evidenceFiles.push(obj)
        },
        removeFile(file) { // 移除文件钩子
            console.log(file);
            this.formData.evidenceFiles = this.formData.evidenceFiles.filter(item => item.fileUri !== file.response.content.url)
        },
        // 提交表单
        submit() {
            this.$refs.certificationForm.validate(async (valid) => {
                if (valid) {
                    const {data} = await this.$apis.userServe.claimScholar(this.formData);
                    if (data.resultCode === '201') {
                        this.$Message.error(data.content.msg)
                    } else {
                        this.$Message.success('提交成功')
                        this.close()
                    }
                } else {
                    this.$Message.error('还有必填项未填!');
                }
            })
        },
        // 关闭Modal
        close() {
            this.$refs.certificationForm.resetFields()
            this.formData = {
                claimedUserId: this.$route.query.id,
                idCardFront: '',
                idCard: '',
                idCardBack: '',
                evidencePhotos: [],
                evidenceFiles: [],
            }
            
            this.$refs.files.clearFiles()
            this.$emit("closeModal")
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep .ivu-modal-content {
    padding: 40px;
    border-radius: 18px;
    overflow: hidden;
    .ivu-modal-close {
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        background: #00A4FF;
        border-radius: 0px 0px 0px 18px;
        top: 0;
        right: 0;
        .ivu-icon {
            top: 5px;
            color: #fff;
            font-size: 32px;
        }
    }
    .ivu-modal-header {
        position: relative;
        padding: 0;
        border: none;
        .modal-title {
            font-size: 24px;
            font-family: Source Han Sans SC;
            font-weight: 400;
            color: #333333;
        }
        .modal-subtitle {
            margin-top: 10px;
            font-size: 14px;
            font-family: Source Han Sans SC;
            font-weight: 400;
            color: #999999;
        }
    }
    .ivu-modal-body {
        padding: 0;
        margin-top: 30px;
        .content-box {
            .ivu-form-item-label {
                font-size: 16px;
                font-family: Source Han Sans SC;
                font-weight: 400;
                color: #666666;
            }
            .ivu-input {
                height: 40px;
            }
            .shenfenUpload {
                display: inline-block;
                width: 300px;
                height: 188px;
                .ivu-upload {
                    width: 100%;
                    height: 100%;
                    .IDimg {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .tishi {
                font-size: 14px;
                font-family: Source Han Sans SC;
                font-weight: 400;
                color: #999999;
            }
            .tishi1 {
                margin-bottom: 14px;
                font-size: 14px;
                font-family: Source Han Sans SC;
                font-weight: 400;
                color: #333333; 
            }
            .files {
               .ivu-icon-ios-close {
                   margin-top: 7px;
               }
            }
            .filesBtn {
                width: 120px;
                height: 40px;
                background: #FFFFFF;
                border: 1px solid #00A4FF;
                border-radius: 8px;
                font-size: 14px;
                font-family: Source Han Sans SC;
                font-weight: 400;
                color: #00A4FF;
            }
            .photoUplode {
                display: inline-block;
                width: 134px;
                height: 134px;
                .ivu-upload {
                    width: 100%;
                    height: 100%;
                    .photoUplodeBox {
                        width: 100%;
                        height: 100%;
                        line-height: 134px;
                    }
                }
            }
            .demo-upload-list {
                position: relative;
                display: inline-block;
                overflow: hidden;
                margin-right: 4px;
                width: 134px;
                height: 134px;
                text-align: center;
                line-height: 134px;
                border: 1px solid transparent;
                border-radius: 4px;
                background: #fff;
                box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
                img {
                    width: 100%;
                    height: 100%;
                }
                .demo-upload-list-cover {
                    display: none;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background: rgba(0, 0, 0, 0.6);
                    i {
                        margin: 0 2px;
                        color: #fff;
                        font-size: 20px;
                        cursor: pointer;
                    }
                }
            }
            .demo-upload-list:hover .demo-upload-list-cover {
                display: block;
            }
        }
    }
    .ivu-modal-footer {
        padding: 0;
        border-top: none;
        .Btn {
            width: 140px;
            height: 40px;
            background: #00A4FF;
            border-radius: 8px;
            font-size: 14px;
            font-family: Source Han Sans SC;
            font-weight: 400;
            color: #FFFFFF;
        }
    }
    .ivu-upload-list-file {
        span {
            display: inline-block;
            max-width: 90%;
            white-space:nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: middle;
        }
    }
}
</style>